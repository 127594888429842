import { render, staticRenderFns } from "./ProgramSpeakerItem.vue?vue&type=template&id=6c6b3d8a"
import script from "./ProgramSpeakerItem.vue?vue&type=script&lang=ts"
export * from "./ProgramSpeakerItem.vue?vue&type=script&lang=ts"
import style0 from "./ProgramSpeakerItem.vue?vue&type=style&index=0&id=6c6b3d8a&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppAvatar: require('/builds/project/mashuk/frontend/components/app/AppAvatar.vue').default,AppSpinner: require('/builds/project/mashuk/frontend/components/app/AppSpinner.vue').default,AppIcon: require('/builds/project/mashuk/frontend/components/app/AppIcon.vue').default,UserCalendarSession: require('/builds/project/mashuk/frontend/components/user/calendar/UserCalendarSession.vue').default})
